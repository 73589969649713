import React, { useRef, useContext, useState, lazy, Suspense } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Hero } from '../components/Home/Hero';
import { Testimonials } from '../components/Testimonials';
import { Resources } from '../components/General/Resources';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import FormModalContext from '../context/FormModalContext';
import { SEO } from '../components/SEO';
// import { Intro } from '../components/Home/Intro';
// import { Offerings } from '../components/Home/Offerings';
import { HeroModal } from '../components/Home/Hero/HeroModal';
import { CustomerLogos } from '../components/CustomerLogos';
import { HomeBody } from '../components/Home/HomeBody';
import { PromotionModal } from '../components/General/PromotionModal';
import { HomeNewsBar } from '../components/Home/HomeBody/HomeNewsBar';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	testimonialTitle: {
		color: theme.workwaveBlue,
		textAlign: 'center',
		fontWeight: 'bold',
		marginBottom: '2rem',
		marginTop: '2rem',
	},

	// centerBackground: {
	// 	background:
	// 		'linear-gradient(339.51deg, #002D5C 26.73%, #055291 46.94%, #2F7FC1 67.6%, #002D5C 88.26%)',
	// 	padding: '6rem 0',
	// },

	formCont: {
		padding: '24rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '16rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '8rem 0',
		},
	},
	testimonialWrapper: {
		background: 'transparent',
		padding: '2rem 0',
		position: 'relative',
		// marginTop: '-4rem',
		[theme.breakpoints.down('sm')]: {
			marginTop: '-2rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: '-1rem',
		},
	},
	badgesGrid: {
		justifyContent: 'center',
		alignItems: 'center',

		// [theme.breakpoints.down('md')]: {
		// 	justifyContent: 'space-evenly',
		// },
	},
	g2Image: {
		width: '550px',
		maxWidth: '100%',
    marginBottom: '3rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '1rem',
      marginBottom: '2rem',
		},
	},
}));

const IndexPage = ({ data, location }) => {
	const home = data.home.edges[0].node;
	const classes = useStyles();
	const formRef = useRef();

	const { formModalOpen, handleModalClick, setFormModalOpen, email } =
		useContext(FormModalContext);

	const [heroModalOpen, setHeroModalOpen] = useState(false);

	const {
		metaTitle,
		metaDescription,
		hero,
		testimonialHeader,
		testimonials,
		customerLogos,
		customerLogosHeader,
		marketoId,
    pardotUrl,
		contactForm,
		formBgImage,
		_rawIntroText,
		_rawFeatureSection,
		homeLinksHeader,
		homeLinks,
		homeLinksCallout,
		homeCta,
		resources,
		resourceTitle,
		_rawResourceBody,
		promotionModal,
    homeNewsBar,
	} = home;

	const { productFeatures } = data;
	const sm = useMediaQuery('(max-width: 600px)');
	const xs = useMediaQuery('(max-width: 481px)');

	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero
				hero={hero}
				homeHeroVideo={hero.homeHeroVideo}
				setHeroModalOpen={setHeroModalOpen}
				productFeatures={productFeatures}
			/>
      <Container>
        <Grid container className={classes.badgesGrid}>
          <img src='https://svgshare.com/i/1AV9.svg' className={classes.g2Image}></img>
        </Grid>
      </Container>
      {homeNewsBar.newsBarText ? (
      <Container>
        <HomeNewsBar
          background={homeNewsBar.newsBarBackground}
          header={homeNewsBar._rawNewsBarBlockHeader}
          newsBarLink={homeNewsBar.newsBarLink}
          newsBarText={homeNewsBar.newsBarText}
          newsBarImage={homeNewsBar.newsBarImage}
          internalLink={homeNewsBar.internalLink}
          simulations
        />
      </Container> 
      ) : ' '}

      <HomeBody
        intro={_rawIntroText}
        body={home}
        homeCta={homeCta}
        homeLinksCallout={homeLinksCallout}
        home
      />
			<WaveDownSVG height='213' width='100%' fill='white' />
			<div
				style={{
					backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
				}}
				className={classes.formCont}>
				<Form
					formId={marketoId}
          pardotUrl={pardotUrl}
					contactForm={contactForm}
					privacy
					location={location}
				/>
			</div>
			<WaveUpSVG height='213' width='100%' fill='#FFFFFF' />
			<Container>
				<Resources
					header={resourceTitle}
					resources={resources}
					subheader={_rawResourceBody}
				/>
			</Container>
			<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />

			<HeroModal
				open={heroModalOpen}
				setHeroModalOpen={setHeroModalOpen}
				homeHeroVideo={hero.homeHeroVideo}
				playing
			/>
			{!!promotionModal ? (
				<PromotionModal promotionModal={promotionModal} />
			) : null}
		</>
	);
};

export const query = graphql`
	query HomePageQuery {
		home: allSanityHome {
			edges {
				node {
					metaTitle
					metaDescription
					hero {
						homeHeroVideo
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						mobileBackground {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						videoImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						_rawContent
					}
					promotionModal {
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						link
					}
          homeNewsBar {
            newsBarHeader
            newsBarBackground {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            newsBarLink
            newsBarText
            internalLink
            newsBarImage {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            _rawNewsBarBlockHeader
          }
					_rawIntroText
					# homeLinksHeader
					homeLinksCallout {
						header
						description
						ctaText
						ctaLink
					}
					featureSection {
						_key
						title
						cardTitle
						cardFaIcon
						cardIconColor {
							hexValue
						}
						bodySubHeader
						bodyHeader
						image {
							featureImage {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
						}
						videoVariant
						androidLink
						iosLink
						qbLink
						# svgVariant
						thumbnailVideoUrl
						ctaText
						ctaLink
						_rawBodyCopy
						internalLink
						links {
							title
              cardTitle
							slug {
								current
							}
							marketingLinkIcon
							linkIconColor {
								hexValue
							}
							internalLink
						}
					}
					homeCta {
						ctaHeader
						ctaSubheader
						ctaText
						ctaLink
						internalLink
						ctaBackground {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					testimonialHeader
					testimonialBackground {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					testimonials {
						title
						header
						testimonialText
						nameAndTitle
						company
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					customerLogosHeader
					customerLogos {
						title
						logo {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					contactForm {
						header
						privacyPolicy
					}
					formBgImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					marketoId
          pardotUrl
					resourceTitle
					_rawResourceBody
					resources {
						title
						blurb
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						ctaText
						ctaLink
					}
				}
			}
		}
		productFeatures: allSanityProductFeatures {
			edges {
				node {
					title
					metaTitle
					metaDescription
					homeHeroPillTitle
					slug {
						current
					}
					primary
					linkIconColor {
						hexValue
					}
					marketingLinkIcon
				}
			}
		}
	}
`;

export default IndexPage;
